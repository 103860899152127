.formula-image {
  display: block;
  max-width: 50%;
  margin: 20px auto 0 auto;
  filter: brightness(0);
}

:global .dark {
  .formula-image {
    filter: brightness(100);
  }
}
